#usuarioResponsavelTarefaContent {
    display: flex;
    align-items: center;
    height: 60px;
}

#usuarioResponsavelTarefa {
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

#apagarIconeTarefa {
    width: 30px;
    margin: 10px;
    box-sizing: border-box;
}

#apagarIconeTarefa:hover {
    border: solid 1px #FFFFFF;
    border-radius: 50%;
}

#maisOpcoesFiltrosTarefas, .maisOpcoesFiltrosComponente {
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.2);
}

#maisOpcoesFiltrosTarefasContainer, .maisOpcoesFiltros {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: max-content;
    height: max-content;
    padding: 20px;
    background-color: #FEFCFF;
    border-radius: 5px;
    top: 20px;
    animation: animacaoModalFundo 300ms;
}

@keyframes animacaoModalFundo {
    0% {
        transform: translateY(-300px);
        opacity: 0;
    }
}

#filtrosAvancadosTarefas, .filtrosAvancados {
    width: 30px;
}

#filtrosContainerTitulo, .filtrosContainerTitulo {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
    border-bottom: #FF6B6C solid 1px;
}

#opcoesFiltrosTarefas section, .opcoesFiltros section {
    display: flex;
    gap: 20px;
}

#opcoesFiltrosTarefas span, .opcoesFiltros span {
    display:  flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
}

#prazoTarefa {
    width: 100px;
}

#botoesContainerFiltrosTarefas {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    border-top: #FF6B6C solid 1px;
    padding-top: 10px;
}

@media (max-width: 1000px) {

    #tipoTarefa, #responsavelTarefa, #usuarioResponsavelTarefaContent, #prioridadeTarefa, #prazoTarefa {
        display: none;
    }

    #tabelaSituacaoTarefa {
        width: 30px;
    }
}