#graficoNpsAtendimento {
    background-color: #F5F5FA;
    flex: 1 0 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(60vh - 40px);
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    border-left: #FF6B6C solid 5px;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
    text-align: center;
    box-sizing: border-box;
}

#graficoNpsAtendimentoP {
    font-family: 'Panton-BlackCaps';
    font-weight: 900;
    margin-top: 10px;
}

@media (max-width: 1000px) {
    #graficoAvaliacaoAtendimento {
        flex: 1 0 80px;
    }
}