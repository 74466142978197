.tabelaCobrancasContainer {
    min-width: 900px;
    max-height: calc(100vh - 135px);
    overflow: auto;
}
#modalAssinaturaTituloContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    height: 60px;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
}

#fecharModalAssinatura {
    cursor: pointer;
    width:  30px;
    transition: all 500ms;
}

#fecharModalAssinatura:hover {
    transform: scale(105%);
}

.dadosCobranca {
    padding: 0 15px;
}

.valorTotalCobranca {
    min-width: 100px;
}

.descricaoCobranca {
    padding-right: 20px;
}
.iconeEfetuarPagamento {
    width: 30px;
    padding: 10px;
    transition: all 300ms ease-in;
}

.iconeEfetuarPagamento:hover {
    transform: scale(105%);
}

.processandoDadosCobrancas {
    width: 100%;
    height: 410px;
}

.linhaTabelaListaCobrancasAssiantens:hover {
    background-color: #F1F1F3 !important;
    color: black;
}

.ladoDireitoCobrancasMinhasAssinaturas {
    display: flex;
    flex-direction: row;
}


