#usuarioAlteracaoModeloProcessoContent {
    display: flex;
    align-items: center;
    height: 60px;
}

#ultimaAlteracaoUsuarioModeloProcesso {
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
}


@media (max-width: 1000px) {

    #usuarioAlteracaoModeloProcessoContent, #ultimaAlteracaoUsuarioModeloProcesso, #ultimaAlteracaoModeloProcesso {
        display: none;
    }

    #tabelaSituacaoTarefa {
        width: 30px;
    }

    #situacaoModeloProcesso {
        padding: 0 15px;
    }
}