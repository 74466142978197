#semAvaliacaoAtendimento {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#semDadosGrafico {
    width: 15%;
}

#semAvaliacaoAtendimento p {
    margin-top: 15px;
    text-align: center;
}