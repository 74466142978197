#tabelaProcessosTarefas {
    width: 100%;
}

.tarefasProcessosAdicionarTarefa {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px;
    margin-top: 10px;
    background-color: #F1F1F3;
    border-radius: 5px;
    border-bottom: #FF6b6C solid 1px;
}
.tarefasProcessosAdicionarTarefa p {
    font-weight: bolder;
    
    
}

#tipoTarefaProcesso, #prazoTarefaProcesso {
    padding: 0 15px;
}

.tabelaListaTarefasProcessos {
    height: calc(100dvh - 380px);
    overflow: auto;
}

#prazoTarefaProcesso {
    width: 120px;
}

@media (max-width: 1000px) {

    #tipoTarefaProcesso, #prazoTarefaProcesso, #situacaoTarefaProcesso {
        display:  none;
    }
}