#tabelaModelosProcessosTarefas p {
    font-weight: bolder;
    padding: 10px 0px;
    margin-top: 10px;
    border-bottom: #FF6b6C solid 1px;
}

#tabelaModelosProcessosTarefas span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

#usuarioResponsavelModeloTarefaContent {
    display: flex;
    align-items: center;
    height: 60px;
}

#usuarioResponsavelModeloTarefa {
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

#tipoTarefaModeloProcesso {
    padding: 0 20px;
}

@media (max-width: 1000px) {

    #tipoTarefaModeloProcesso {
        display:  none;
    }
}