.header-processo-seletivo {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.detalhes-logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.detalhes-processo-seletivo {
  flex: 1;
  white-space: nowrap;
}

.dados-empresa {
  color: #737373;
  margin: 4px 0;
}

.dados-processo-seletivo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.tag {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0px 5px;
}

.tag-padrao {
  background-color: #f3f3f3;
  color: #1a1a1a;
}

.nome-azul {
  background-color: #f3f3f3;
  color: #1a73e8;
}

.nome-vermelho {
  background-color: #f3f3f3;
  color: #a41623;
}

.nome-amarelo {
  background-color: #f3f3f3;
  color: #f7b558;
}

.btn-detalhes {
  margin-left: auto;
  padding: 8px 16px;
  border: 1px solid #001b48;
  color: #001b48;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
}

.btn-detalhes:hover {
  background-color: #001b48;
  color: white;
}

.btn-finalizar {
  color: #a41623;
  border: 1px solid #a41623;
}

.cartao {
  margin: 10px;
  min-width: 300px;
  min-height: 300px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: calc(100vh - 200px);
  overflow: auto;
}

.cartoes-container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  padding: 16px;
  overflow-x: auto;
  white-space: nowrap;
  min-height: calc(100vh - 190px);
}

.cabecalho-amarelo {
  background-color: #facc15;
  text-align: center;
  padding: 10px 0;
}

.cabecalho-azul {
  background-color: #018abe;
  text-align: center;
  padding: 10px 0;
}

.cabecalho-verde {
  background-color: #25ae88;
  text-align: center;
  padding: 10px 0;
}

.titulo {
  font-size: 1rem;
  font-weight: bold;
  color: white;
}

.conteudo {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.item {
  display: flex;
  align-items: center;
  background-color: #e5e5e5;
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
  &:hover {
    scale: 1.05;
    transition: ease 0.4s;
  }
}

.reprovado {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
}

.item-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: contain;
}

.item-detalhes {
  margin-left: 10px;
  flex: 1;
}

.item-nome {
  font-weight: bold;
  white-space: normal;
  color: #1a1a1a;
}

.item-profissao {
  color: #4b5563;
    white-space: normal;
}

.item-acoes {
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
}

.icone {
  font-size: 1.3rem;
  border: none;
  cursor: pointer;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.campo-etapas {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-adicionar-etapa {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2em;
}

.lista-etapas {
  margin-top: 10px;
  list-style-type: decimal;
}

.item-etapa {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.proximaEtapa {
  padding: 10px;
}

.btn-detalhes-fechar {
  padding: 4px 8px;
  border: 1px solid #e21b1b;
  color: #e21b1b;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
}

.avatarCadastroProcessoSeletivo {
    width: 100px;
    border-radius: 10px;
}

@media (max-width: 1300px) {
  .header-processo-seletivo {
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .section-tags {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
  }
}
