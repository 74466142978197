#tituloDreContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: #FF6B6C solid 1px;
    border-radius: 10px;
}

#tituloDreContainer form {
    display: flex;
    align-items: center;
    gap: 20px;
}

#tituloDreContainer span {
    display: flex;
    flex-direction: column;
}

#tituloDreContainer span:nth-child(3) {
    margin-bottom: -20px;
}

#tabelaDreContainer {
    height: calc(100vh - 115px);
    overflow-y: auto;
}

#tabelaDreContainer::-webkit-scrollbar, #contasBancariasContainer::-webkit-scrollbar {
    background-color: #FFFFFF;
    border-radius: 0 20px 20px 0;
    width: 5px;
}

#tabelaDreContainer::-webkit-scrollbar-thumb, #contasBancariasContainer::-webkit-scrollbar-thumb {
    background-color: #FF6B6C;
    border-radius: 3px;
}

.primeiraColunaDre {
    max-width: 60px;
    box-sizing: border-box;
}

.grupoDre {
    background-color: rgba(106, 108, 114, 0.15);
    font-weight: bold;
}

.centroDre {
    padding-left: 25px;
    border-radius: 10px 0 0 10px;
    font-weight: bold;
}

.categoriaDre {
    padding-left: 30px;
    border-radius: 10px 0 0 10px;
}

.receitaOperacionalDre {
    background-color: #25AE88;
}

.lucroOperacionalDre, .lucroLiquidoDre {
    background-color: #018ABE;
}