#container-gerenciar-modulos {
  display: flex;
  flex-direction: column;
  border: 1px solid #ff6b6c;
  border-radius: 4px;
  margin-top: 15px;
}

#container-gerenciar-modulos > .summary-gerenciar-modulos {
  padding: 5px;
  font-weight: bold;
}

#section-modulos {
  flex-direction: column;
  border-top: 1px solid #ff6b6c;
}

.modulo-especifico {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;
}

.modulo-especifico span {
  width: max-content;
}

.modulo-especifico > div > button {
  width: 150px;
}

.modulo-especifico > div > button,
.modulo-especifico > div > p {
  margin: 5px;
}

.header-modulo {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 5px;
}

#container-gerenciar-modulos .summary-gerenciar-modulos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

#container-gerenciar-modulos .summary-gerenciar-modulos::after {
  content: "▶";
  color: #000000;
  font-weight: bold;
  padding: 0 5px;
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
}

#container-gerenciar-modulos[open] .summary-gerenciar-modulos::after {
  transform: rotate(90deg);
}

.btn-novo-modulo {
  margin: 10px auto;
  width: 150px;
  overflow: hidden;
}

.Ativo{
  color: #25AE88;
}

.Inativo{
  color: #E21B1B;
}

.situacao-modulo{
  margin-left: 5px;
}