.modalDividido {
  display: flex;
  flex-direction: row;
}

.container-left-side,
.container-right-side {
  padding: 15px;
}

.container-right-side {
  border-left: 0.5px solid #a9a9a9;
}

.container-right-side,
.container-modal-pergunta {
  display: flex;
  flex-direction: column;
}

.titulo-dados-modal {
  color: #ff6b6c;
  font-weight: 500;
  margin-bottom: 10px;
}

.container-modal-pergunta,
.container-modal-pergunta > span > span {
  padding: 10px;
  gap: 10px;
}

.detalhes-dados-vaga {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2px 0px;
}

.detalhes-dados-vaga > p {
  margin: 1px 0px;
  font-weight: 600;
  font-family: "Calibri", sans-serif;
}

.dados-vaga {
  color: rgb(99, 99, 99);
  word-break: break-all;
  width: 500px;
}

.dados-candidatos-aprovados {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
  font-family: "Calibri", sans-serif;
}

.textAreaModal {
  resize: none;
  width: 100%;
}

.botoes-modal {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.avatar-candidato-aprovado {
  width: 30px;
}

.lista-candidato-aprovado {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px 0px;
}

@media (max-width: 800px) { 
  .container-right-side,
  .container-left-side {
    border: none;
  }
  .modalDividido {
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: center;
  }

  .dados-vaga {
    width: none;
  }
  .titulo-dados-modal {
    display: flex;
    justify-content: center;
  }
  .detalhes-dados-vaga > p {
    width: 100%;
  }
  .container-modal-pergunta > span > select{
    width: 100%;
  }
}

@media (max-width: 1150px) {
  .container-right-side,
  .container-left-side {
    width: 300px;
    border: none;
  }
  .detalhes-dados-vaga {
    width: 300px;
  }
  .dados-vaga{
    max-width: 300px;
  }
}
