.container-btn-modal-modulo {
  display: flex;
  justify-content: space-evenly;
}

.select-modal-modulo {
  width: 100%;
  margin: 10px 0px;
}

.input-modal-modulo-documento {
  margin-bottom: 10px;
  width: 94%;
}
