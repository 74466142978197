#fundo_modal_recuperar_senha {
    position: fixed;
    width: calc(100% + 180px);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 20;
}

#fundo_recuperar_senha {
    background-color: #EAF1F2;
    width: max-content;
    height: max-content;
    margin: auto;
    position: fixed;
    border-radius: 10px;
    padding: 10px;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    animation: modalRecuperarSenha 0.5s;
}

@keyframes modalRecuperarSenha {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

#div_recuperar_senha {
    position: relative;
    box-sizing: border-box;
}

#div_recuperar_senha input {
    width: 90%;
    padding: 10px;
    font-size: 1em;
    border: #00A6a6 solid 1px;
    border-radius: 10px;
    box-shadow: 5px 3px 7px #a1a1a1;
    margin: 10px 0px;
}

#div_recuperar_senha span {
    position: absolute;
    top: 22px;
    left: 10px;
    font-size: 0.9em;
    pointer-events: none;
    transition: 0.4s;
    box-sizing: border-box;
}

#div_recuperar_senha input:focus {
    border: none;
    font-weight: bolder;
    outline: 1px solid #00A6a6;
}

#div_recuperar_senha input:focus + span, #div_recuperar_senha input:valid + span {
    top: 0px;
    left: 0px;
    font-size: 0.9em;
    padding: 0px 10px;
    background-color: #EAF1F2;
    box-sizing: border-box;
}

#recuperar_senha_botao {
    border-top: #D7AF70 solid 1px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

#recuperar_senha_botao div {
    margin-top:  10px;
    padding: 5px 10px;
}