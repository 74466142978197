.notificacoesContainer {
    position: absolute;
    top: 15px;
    right: 10px;
    width: max-content;
    max-width: 400px;
    height: 80px;
    background-color:rgba(241,241,243,0.6);
    backdrop-filter: blur(8px);
    z-index: 1500;
    border-radius: 10px 0 0 10px;
    box-shadow: 0px 16px 100px 66px rgba(0,0,0,0.1);
    animation: animacaoContainer 5s ease-in-out;
}

@keyframes animacaoContainer {
    0% {
        transform: translateY(-300px);
        opacity: 0;
    }
    20% {
        transform: translateY(0);
        opacity: 1;
    }
    80% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-300px);
        opacity: 0;
    }
}

.notificacoesContainer section{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
}

.notificacoesContent {
    position: relative;
}

.notificacoesContent::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    height: 2px;
    width: 100;
    overflow: hidden;
    animation: AnimacaoBarraNotificacao 5s ease-in-out;
}

@keyframes AnimacaoBarraNotificacao {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

.sucessoContent::before {
    content: '';
    background-color: #25AE88;
}

.sucesso {
    border-left: #25AE88 solid 10px;
}

.sucesso img {
    width: 40px;
    border-radius: 50%;
    border: #25AE88 solid 2px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.erroContent::before {
    content: '';
    background-color: #E21B1B;
}

.erro {
    border-left: #E21B1B solid 10px;
}


.erro img {
    width: 40px;
    border-radius: 50%;
    border: #E21B1B solid 2px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}