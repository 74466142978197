.avatarProjeto {
  width: 35px;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.visualizar-projeto-icon {
  font-size: 30px;
}

.avatarProjeto:nth-child(2),
.avatarProjeto:nth-child(3),
.avatarProjeto:nth-child(4) {
  margin-left: -10px;
}

.visualizar-projeto-icone {
  font-size: 28px;
  background-color: #ff6b6c;
  border-radius: 50%;
  padding: 5px;
  color: #ffffff;
}

.visualizar-projeto-icone:hover {
  border: solid 1px #ffffff;
  cursor: pointer;
}
