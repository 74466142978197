#dashboardFinanceiroContainer, .graficoDashboardContainer {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

#dadosDashboardFinanceiro, .dadosDashboardDefault {
    flex: 1 0 200px;
    display: flex;
    width: 100%;
    height: 100px;
    align-items: flex-end;
    justify-content: space-between;
    background-color: #FFF;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    border-left: #FF6B6C solid 5px;
    border-radius: 15px;
    padding: 10px 15px;
    margin: 10px;
    box-sizing: border-box;
}

#dadosDashboardFinanceiro img, .dadosDashboardDefault img {
    width: 70px;
}

#valoresFinanceiroContent, .dashboardDefaultContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

#valoresFinanceiroContent h1 {
    font-family: 'Panton-BlackCaps';
    font-size: clamp(12px, 2em, 20px);
    font-weight: bolder;
}

#valoresFinanceiroContent p, .dashboardDefaultContent p {
    color: #000;
    font-size: 1em;
    margin-top: 15px;
}

#graficosDashboardFinanceiro, .graficoDashboardDefault {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: calc(100vh - 120px);
}

@media (max-width: 1000px) {
    #graficosDashboardFinanceiro, .graficoDashboardDefault{
        display: flex;
        flex-wrap: wrap;
    }
}