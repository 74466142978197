#menuOpcoes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    font-size: 1em;
    font-weight: 500;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    transition: all 300ms ease-out;
}

#menuOpcoes:hover {
    width: calc(100% - 20px);
    margin: 0 10px;
    border-radius: 80px;
    color: #F1F1F3;
    background-color:  #FF6B6C;
    box-sizing: border-box;
}

#menuOpcoes {
    position: relative;
    width: 100%;
}

#menuOpcoes h2 {
    position: absolute;
    right: 0;
    background-color: #00A6A6;
    border-radius: 5px;
    padding: 3px 5px;
    color: #FFF;
    margin-right: 10px;
    font-size: 10px;
    animation: AnimacaoNotificacaoMenuLateral 500ms ease-in-out;
}

@keyframes AnimacaoNotificacaoMenuLateral {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#menuOpcoes h3 {
    position: absolute;
    right: 0;
    background-color: #6A6C72;
    border-radius: 5px;
    padding: 3px 5px;
    color: #FFF;
    margin-right: 10px;
    font-size: 10px;
    animation: AnimacaoNotificacaoMenuLateral 500ms ease-in-out;
}

#iconeMenu {
    width: 20px;
    height: 20px;
    margin: 10px 15px;
    padding: 5px;
    background-color:  #FF6B6C;
    border-radius: 50%;
}

@media (max-width: 1600px) {
    #iconeMenu {
        margin: 5px 15px;
    }
}

