.toggleListaUsuarios {
    display: none;
}

.toggleListaUsuarios + #labelToggleListaUsuarios {
    width: 32px;
    height: 20px;
    background: #E21B1B;
    display: block;
    position: relative;
    border-radius: 20px;
    padding: 2px;
    transition: 200ms;
    cursor: pointer;
}

.toggleListaUsuarios + #labelToggleListaUsuarios::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #F1F1F3;
    display: block;
    border-radius: 50%;
    right: unset;
    left: 2px;
    top: 2px;
    transition:  200ms;
}

.toggleListaUsuarios:checked + #labelToggleListaUsuarios {
    background: #25A388;
}

.toggleListaUsuarios:checked + #labelToggleListaUsuarios::before {
    content: '';
    left: calc(100% - 22px);
}