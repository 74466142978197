.novoContainerTitulo h1 {
  flex-grow: 1;
  text-align: center;
}

.adicionarModalIcone {
  cursor: pointer;
  width: 25px;
  height: 25px;
  transition: transform 0.2s ease-in-out;
}

.adicionarModalIcone:hover {
  transform: scale(1.1);
}

.listaAvaliacao {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fafafa;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.listaAvaliacao span {
  font-size: 1rem;
  color: #555;
  margin-right: 15px;
  flex-grow: 1;
}

.listaAvaliacao img {
  cursor: pointer;
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
  margin: 0px 2px;
}

.listaAvaliacao img:hover {
  transform: scale(1.2);
}

.visivel {
  color: #fff;
  font-weight: bold;
  background: #25ae88;
  padding: 3px;
  border-radius: 3px;
  margin: 0px 10px;
}

.oculto {
  color: #fff;
  font-weight: bold;
  background: #e21b1b;
  padding: 3px;
  border-radius: 3px;
  margin: 0px 10px;
}

@media (max-width: 768px) {
  .containerModalCentro {
    width: 90%;
    overflow: auto;
  }

  .novoContainerTitulo h1 {
    font-size: 1rem;
  }

  .listaAvaliacao span {
    margin-bottom: 8px;
  }

  .listaAvaliacao {
    width: auto;
  }

  .adicionarModalIcone {
    width: 20px;
    height: 20px;
  }

  .listaAvaliacao img {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 1250px) {
  .containerModalCentro {
    overflow: auto;
  }
}