#paginaContainer {
    width: 100%;
    height: 100dvh;
}

#fundoContainerPagina {
    height: calc(100dvh - 100px);
    overflow-y: auto;
    animation: AnimacaoFundoDados 300ms ease-in-out;
}

@keyframes AnimacaoFundoDados {
    from {
        opacity: 0;
    }   
    to {
        opacity: 1;
    }
}

#fundoContainerPagina::-webkit-scrollbar {
    background-color: #FFFFFF;
    width: 5px;
}

#fundoContainerPagina::-webkit-scrollbar-thumb {
    background-color: #FF6B6C;
    border-radius: 3px;
}

#tabelaListaContainer {
    width: calc(100% - 40px);
    margin: 20px;
    border-collapse: separate;
    border-spacing: 0 15px;
}

.titulo-tabela-grupo{
    margin-top: 20px;
}

#ladoEsquerdoContent, .ladoEsquerdoContent {
    padding-left: 20px;
}

#primeiraColuna, .primeiraColuna {
    padding-left: 20px;
    border-radius: 10px 0 0 10px;
    border-left: #FF6B6C solid 5px;
    min-width: 100px;
}

#ladoDireitoContent, .ladoDireitoContent {
    border-radius: 0 10px 10px 0;
    padding: 0 10px;
}

.usuarioResponsavelContent, .avatarContent {
    display: flex;
    align-items: center;
    height: 60px;
}

.usuarioResponsavel, .avatar {
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.apagarIconeItemTabela {
    width: 30px;
    margin: 10px;
    box-sizing: border-box;
}

.apagarIconeItemTabela:hover {
    border: solid 1px #FFFFFF;
    border-radius: 50%;
}

@media (max-width: 1000px) {

    #primeiraColuna {
        min-width: 40px;
        padding: 10px;
    }
}
