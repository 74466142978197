.texto-destaque {
  margin-top: 8px;
  background-color: #fafafa;
  padding: 8px;
  border-radius: 4px;
  color: #555555;
}

.icone-editar {
  font-size: 1.5rem;
  cursor: pointer;
}

.containerDetalhesProcesso {
  min-width: 450px;
}

.botoesContainer {
  margin-top: 10px;
}

.espacamento {
  display: flex;
  gap: 5px;
}

.overflow-auto {
  overflow: auto;
}

@media (max-width: 1000px) {
  .containerDetalhesProcesso {
    min-width: 100%;
  }
}
