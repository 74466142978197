#usuarioResponsavelAjudaContent {
    display: flex;
    align-items: center;
    height: 60px;
}

#usuarioResponsavelAjuda {
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

#curtirSugestao {
    width: 25px;
}

#curtirSugestao:hover {
    border: solid 1px #FFF;
    border-radius: 50%;
}


@media (max-width: 1000px) {

    #usuarioResponsavelAjudaContent, #responsavelSuporte {
        display: none;
    }
}