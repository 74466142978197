#dashboardContainer {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

#dadosDashboard {
    flex: 1 0 100px;
    display: flex;
    width: 100%;
    height: 100px;
    align-items: flex-end;
    justify-content: space-between;
    background-color: #FFF;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    border-left: #FF6B6C solid 5px;
    border-radius: 15px;
    padding: 10px 15px;
    margin: 10px;
    box-sizing: border-box;
}

#dadosDashboard img {
    width: 70px;
}

#quantidadeClientes {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

#quantidadeClientes h1, .dashboardDefaultContent h1 {
    font-family: 'Panton-BlackCaps';
    font-size: 4em;
    font-weight: bolder;
}

#quantidadeClientes p {
    color: #000;
    font-size: 1em;
    margin-top: 15px;
}

#clientes_ativos, #clientes_mes, #clientes_inativos, #clientes_totais {
    padding-right: 10px;
    width: clamp(30px, 80px, 100px);
}

#graficosDashboard {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    height: calc(100vh - 140px);
}

@media (max-width: 1000px) {
    #graficosDashboard {
        display: flex;
        flex-wrap: wrap;
    }
}