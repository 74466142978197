.escolhaTempltsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
    gap: 20px;
}

.escolhaTempltsContainer p {
    flex: 1 0 200px;
    text-align: center;
    background-color: #F1F1F3;
    padding: 20px;
    border-radius: 10px;
    border: #F1F1F3 solid 1px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
}

.escolhaTempltsContainer p:hover {
    border: #6A6C72 solid 1px;
}

.btnEscolaTemplat {
    border-top: #FF6B6C solid 1px;
    padding-top: 10px;
}
