#dadosOrganizacaoContainer {
  width: 100%;
  height: 100vh;
}

#sectionDadosOrganizacaoHeard {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  border-bottom: #FF6B6C solid 1px;
  background-color: #F7F8FA;
}

#sectionDadosOrganizacaoHeard span:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 15px 50px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #E6F1FB;
}

#sectionDadosOrganizacaoHeard span:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  width: 10%;
  min-width: 10px;
}

.containerDadosOrhanizacao {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.avatarOrganizacao {
  margin: 20px;
  flex: 1 0 100px;
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 20px;
  border-right: 1px solid rgba(120,143,156, 0.4);
}

.formAtualizarAvatar {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgba(120,143,156, 0.4);
}

.avatarOrganizacao img {
  width: 400px;
  box-sizing: border-box;
  padding: 30px;
}

#formDadosOrganizacao {
  position: relative;
  flex: 1 0 500px;
  padding: 20px;
  height: calc(100vh - 160px);
  overflow-y: auto;
}

#dadosOrganizacao section {
  display: flex;
  font-weight: bold;
  gap: 20px;
  margin-bottom: 20px;
}

#dadosOrganizacao span {
  display: flex;
  width: 100%;
  flex-direction: column;
}

#btnSalvarDadosOrganizacao {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
  background-color: #F7F8FA;
  height: 40px;
  display: flex;
  align-items: flex-end;
  border-top: #FF6B6C solid 1px;
}

@media (max-width: 1000px) {
  #sectionDadosOrganizacaoHeard span:nth-child(1) {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-right: 20px;
  }

  #sectionDadosOrganizacaoHeard p {
    text-align: center;
    margin-bottom: 10px;
  }

  #sectionDadosOrganizacaoHeard span:nth-child(2) {
    justify-content: center;
  }

  #formDadosOrganizacao {
    padding: 20px;
    height: calc(100vh - 290px);
    overflow-y: auto;
  }

  #dadosOrganizacao section{
    flex-direction: column;
    gap: 0;
  }
}
