.tamplatsProcessosDisponiveisContainer {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

.tamplatsProcessosDisponiveisContent {
    display: flex;
    padding: 20px;
    border-radius: 10px;
    border-bottom: solid #6A6C72 2px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    flex: 1 0 400px;
    transition: all 300ms ease-in-out;
    position: relative;
}

.tamplatsProcessosDisponiveisContent:hover {
    background-color: #6A6C72;
    color: #F5F6FA;
}


.tamplatsProcessosDisponiveisContent img {
    width: 80px;
    border-radius: 50%;
    margin: 0 15px;
}

.tamplatsProcessosDisponiveisContent P {
    text-align: justify;
    margin-top: 5px;
}

.importarTemplats {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px 15px;
    margin: 5px;
    cursor: pointer;
    transition: all 300ms ease-in;
    z-index: 100;
}

.importarTemplats:hover {
    background-color: #F1F1F3;
    border-radius: 5px;
    color: #FF6B6C;
}

@media (max-width: 1000px) {
    .tamplatsProcessosContainer section {
        flex: 1 0 200px;
    }

    .tamplatsProcessosContainer img {
        width: 50px;
    }
}