.topicoAdicionarContainer {
    display: flex;
    justify-content: flex-end;
    border-bottom: #FF6B6C solid 1px;
    margin: 10px 0;
}

.topicosModelosContratosContainer {
    height: calc(100dvh - 310px);
    overflow: auto;
}

.topicosModelosContratosContainer::-webkit-scrollbar {
    background-color: #EAF1F2;
    width: 5px;
}

.topicosModelosContratosContainer::-webkit-scrollbar-thumb {
    background-color: #FF6B6C;
    border-radius: 3px;
}

.topicosModelosContratosContent {
    background-color: #FFF;
    margin: 10px 10px 0 0;
    border-radius: 10px;
}

.topicosModelosContratosContent span {
    padding: 10px;
    margin: 10px 0;
    box-sizing: border-box;
    text-align: justify;
}

.topicosModelosContratosContent p {
    margin: 3px 0;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    transition: all 300ms ease-in-out;
}

.topicosModelosContratosContent p:hover {
    background-color: #F5F6FA;
}

.topicosModelosContratosSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    font-weight: bold;
    margin: 10px 0;
    background-color: #FFF;
    padding: 10px;
    border-radius: 5px;
    border-bottom: #FF6B6C solid 1px;
}