.container-dashboard {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}

.container-title-dashboard {
  background-color: #f5f5fa;
  grid-column: 1 / 3;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 10px 30px rgba(166, 173, 201, 0.4);
  border-left: #ff6b6c solid 2px;
  border-radius: 3px;
  margin: 15px;
  box-sizing: border-box;
  padding: 20px 20px 20px 0px;
}

.container-grafico-principal {
  width: 500px;
  height: 200px;
}

.graficoDashboardDefaultDiv{
    background-color: #F5F5FA;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    border-left: #FF6B6C solid 5px;
    border-radius: 15px;
    margin: 10px;
    box-sizing: border-box;
}

.grafico-col-1 {
  grid-column: 1 / 3;
  grid-row: 1;
}

.grafico-col-2 {
  grid-column: span 2;
  grid-row: 1;
}

.grafico-col-3 {
  grid-column: 1 / 2;
  grid-row: 2;
}

.grafico-col-4 {
  grid-column: 2 / 2;
  grid-row: 2;
}

.grafico-col-5 {
  grid-column: 3;
  grid-row: 2;
}

.grafico-col-5-1 {
    grid-column: 3 / 5;
    grid-row: 2;
  }

.grafico-col-6 {
  grid-column: 4;
  grid-row: 2;
}
