#graficoNovosClientesMes {
    background-color: #F5F5FA;
    grid-column: 3;
    grid-row: span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: calc(100vh - 140px);
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    border-left: #FF6B6C solid 5px;
    border-radius: 15px;
    margin: 10px;
    box-sizing: border-box;
}

#graficoNovosClientesMesP {
    font-family: 'Panton-BlackCaps';
    font-weight: 900;
    margin-top: 20px;
}

@media (max-width: 1000px) {
    
    #graficoNovosClientesMes {
        flex: 1 0 200px;
        height: 400px;
    }
}