.container-input-tag {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details-tags {
  padding: 10px;
  background-color: #f9fafb;
  border-radius: 5px;
}

.details-tags > section {
  padding: 10px 10px 0px 10px;
}

.container-input-tag > input {
  width: 100%;
}

.btn-add-tag {
  color: #ff6b6c;
  cursor: pointer;
}

.container-form-inputs {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-color {
  max-width: 45px;
  height: 45px;
  padding: 0;
  cursor: pointer;
  border: none;
}

.nome-tag-contato {
  display: flex;
}

.tagContatoContainerModalEditar > p,
.adicionarTagSugestao > p {
  align-items: center;
  border-radius: 5px;
  color: #fff;
  padding: 5px 10px;
  width: max-content;
  height: max-content;
  flex: 1 0 10rem;
}

.modalEditarTagContato {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 5px;
  max-width: 350px;
}

.modalEditarTagContato > p {
  align-items: center;
  border-radius: 5px;
  color: #fff;
  padding: 5px;
  word-break: break-word;
  width: max-content;
  padding: 3px;
  height: max-content;
  font-size: 12px;
  font-weight: bold;
}

.tagContatoContainerModalEditar {
  display: flex;
  flex-wrap: wrap;
  max-width: 85dvw;
  margin-top: 5px;
  gap: 5px;
  justify-items: flex-start;
  align-items: center;
  justify-content: flex-start;
}

.adicionarTagSugestao > p {
  background-color: #ff6b6c;
  flex: 1 0 120px;
  cursor: pointer;
}

.adicionarTagSugestao {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  max-width: 85dv;
}

.iconeTagTrash {
  cursor: pointer;
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
}

.titulo-tag {
  font-weight: bold;
  margin: 5px 0px;
}
