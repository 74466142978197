#menuLateral {
    width: 230px;
    height: 100dvh;
    background-color: #F1F1F3;
    position: fixed;
    z-index: 100;
}

#menuLateralOpcoes {
    height: calc(100dvh - 135px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}

#menuLateralOpcoes::-webkit-scrollbar {
    background-color: #F1F1F3;
    width: 3px;
}

#menuLateralOpcoes::-webkit-scrollbar-thumb {
    background-color: #FF6B6C;
    border-radius: 5px;
}

#contaUsuarioMenu {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 60px;
    padding: 15px;
    margin: 0 20px 5px 0;
    box-sizing: border-box;
    border-top: #FF6B6C solid 1px;
    bottom: 0;
    left: 0;
    width: 220px;
    z-index: 30;
    transition: all 300ms ease-in-out;
}

#dadosUsarioMenulateral {
    position: absolute;
    top: -110px;
    left: 0;
    position: absolute;
    width: 180px;
    height: max-content;
    padding: 10px 20px;
    background-color: #E6F1FB;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.5);
    z-index: 20;
}

#contaUsuarioMenu p {
    min-width: 50px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 300ms ease-in-out;
}

#contaUsuarioMenu p:hover {
    background-color: #FF6B6C;
    color: #F1F1F3;
}

#logoGesttor {
    border-bottom: #FF6B6C solid 1px;
    margin-bottom: 10px;
    height: 60px;
}

#logoSistemaGesttorMenuLateralExpandido {
    width: 100px;
    cursor: pointer;
    padding-left: 20px;
}

#logoSistemaGesttorMenuLateralRecolhido {
    display: none;
    width: 30px;
    cursor: pointer;
    padding: 12px 15px;
}

#avatarOrganizacaoGesttor {
    position: absolute;
    right: -5px;
    top: -15px;
    width: 40px;
    border-radius: 5px;
}

@media (max-width: 900px) {
    #menuLateral, #contaUsuarioMenu {
        width: 60px;
    }

    #logoSistemaGesttorMenuLateralExpandido {
        display: none;
    }

    #logoSistemaGesttorMenuLateralRecolhido {
        display: block;
    }

    #menuLateral:hover, #menuLateral:hover #contaUsuarioMenu {
        width: 230px;
        animation: AnimacaoMenuLateral 300ms ease-in-out;
    }

    #menuLateral:hover #logoSistemaGesttorMenuLateralExpandido {
        display: block;
    }

    #menuLateral:hover #logoSistemaGesttorMenuLateralRecolhido {
        display: none;
    }

    #contaUsuarioMenu p {
        display: none;
    }

    #menuLateral:hover #contaUsuarioMenu p {
        display: block;
    }

    @keyframes AnimacaoMenuLateral {
        from {
            width: 60px;
        }
        to {
            width: 230px;
        }
    }


}