.formModalNovaBaixa {
    max-height: 300px;
    overflow-y: auto;
    padding-right: 10px;
}

.formModalNovaBaixa section {
    display: flex;
    align-items: center;
    padding: 5px 0;
    gap: 10px;
    font-weight: bold;
}

.formModalNovaBaixa span {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.formModalBaixas {
    height: calc(100vh - 440px);
    overflow-y: auto;
}

.formModalNovaCobrancas {
    overflow-y: auto;
    padding-right: 10px;
}

.formModalNovaCobrancas section {
    display: flex;
    align-items: flex-end;
    padding: 5px 0;
    gap: 10px;
    font-weight: bold;
}

.formModalNovaCobrancas span {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.botoesContainerBaixasLancamentos {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom: 10px;
    width: calc(100% - 40px);
    padding: 10px 0;
    border-top: #FF6B6C solid 1px;
}


#adicionarCategoriaLancamento {
    margin: 5px 0px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

#adicionarCategoriaLancamento:hover {
    color: #FF6B6C;
}

#apagarIconeCategoriaLancamento {
    width: 30px;
    margin-bottom: -15px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

#apagarIconeCategoriaLancamento:hover {
    transform: scale(105%);
}

.tituloModalbaixarLancamento {
    margin: 10px 0;
    padding: 0 10px 0 0;
    border-bottom: #FF6B6C solid 1px;
    font-weight: bold;
}

.valorBaixaLancamento, .contaBaixaLancamento {
    padding: 0 20px;
}

.toggleCobrancaAutomatica, .toggleParcelamentoLancamento {
    display: none;
}

.toggleCobrancaAutomatica + #toggleCobrancaAutomatica, .toggleParcelamentoLancamento + #toggleParcelamentoLancamento {
    width: 32px;
    height: 20px;
    background: #6A6C72;
    display: block;
    position: relative;
    border-radius: 20px;
    padding: 2px;
    transition: 200ms;
    cursor: pointer;
}

.toggleCobrancaAutomatica + #toggleCobrancaAutomatica::before, .toggleParcelamentoLancamento + #toggleParcelamentoLancamento::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #F1F1F3;
    display: block;
    border-radius: 50%;
    right: unset;
    left: 2px;
    top: 2px;
    transition:  200ms;
}

.toggleCobrancaAutomatica:checked + #toggleCobrancaAutomatica, .toggleParcelamentoLancamento:checked + #toggleParcelamentoLancamento {
    background: #25A388;
}

.toggleCobrancaAutomatica:checked + #toggleCobrancaAutomatica::before, .toggleParcelamentoLancamento:checked + #toggleParcelamentoLancamento::before {
    content: '';
    left: calc(100% - 22px);
}

.opcoesCobrancasContainer {
    width: 150px;
}