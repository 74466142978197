#fundoContainerFiltrosDefault {
    width: calc(100% - 40px);
    padding: 20px;
    border-bottom: #FF6B6C solid 1px;
    border-radius: 0 0 20px 20px;
    display: flex;
    align-items: center;
    z-index: 10;
}

#containerEsqueroPesquisa {
    width: 80%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

#inputTermoPesquisa {
    width: 100%;
    flex: 1 0 200px;
}

#selectOpcoesPesquisa {
    flex: 1 0 60px;
    height: 44px;
}

#maisFiltrosPesquisa {
    width: 35px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

#maisFiltrosPesquisa:hover {
    transform: scale(110%);
}

#containerDireitoPesquisa {
    position: relative;
    width: 20%;
    display: flex;
    justify-content: flex-end;
}

#avatarUsuarioGesttor {
    width: 40px;
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

#dadosUsarioDefaul {
    position: absolute;
    width: max-content;
    height: max-content;
    padding: 10px 20px;
    background-color: #E6F1FB;
    top: 50px;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.5);
    z-index: 20;
}

#dadosUsarioDefaul p {
    min-width: 50px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 300ms ease-in-out;
}

#dadosUsarioDefaul p:hover {
    background-color: #FF6B6C;
    color: #E6F1FB;
}

#lupaPesquisaIcon, #btnAdicionarIcon {
    width: 12px;
    height: 12px;
    margin-right: 5px
}

.avatarOrganizacaoGesttor {
    position: absolute;
    right: 35px;
    top: -15px;
    width: 40px;
    border-radius: 5px;
}


@media (max-width: 1200px) {
    #fundoContainerFiltrosDefault {
        align-items: flex-start;
    }
    #inputTermoPesquisa {
        flex: 1 0 200px;
    }
    #btnPesquisarUsuario, #btnNovoUsuario {
        max-width: 1000px;
    }
    #maisFiltrosPesquisa {
        display: none;
    }
}