#resumoTarefas {
    background-color: #F5F5FA;
    flex: 1 0 calc(100vw/2 - 350px);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 40vh;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    border-left: #FF6B6C solid 5px;
    border-radius: 15px;
    margin: 10px;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
}

#tarefasP {
    font-family: 'Panton-BlackCaps';
    font-weight: 900;
    margin-top: 10px;
}

#quadroResumo {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#tarefasAtrasadas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 80%;
    margin-left: 10px;
    border-radius: 10px;
    background-color: #E21B1B;
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    color: #E6F1FB;
}

#semTarefasAtrasadas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 80%;
    margin-left: 10px;
    border-radius: 10px;
    background-color: #25AE88;
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    color: #E6F1FB;
}

#tarefasAtrasadas h1, #semTarefasAtrasadas h1 {
    font-family: 'Panton-BlackCaps';
    font-weight: 900;
    font-size: 5em;
    margin: 30px 0;
}

#demaisTarefas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 65%;
    height: 100%;
}

#demaisTarefas span {
    display: flex;
    padding: 5px 10px;
    height: max-content;
    margin: 10px 0px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border-bottom: #25AE88 solid 2px;
    width: 90%;
    padding: 10px;
    cursor: pointer;
}

#demaisTarefas span:nth-child(even) {
    background-color: #25AE88;
    color: #E6F1FB;
    padding: 10px;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
}

#demaisTarefas h1 {
    font-family: 'Panton-BlackCaps';
    font-weight: 900;
    font-size: 2em;
}

@media (max-width: 1000px) {

    #resumoTarefas {
        height: max-content;
    }

    #quadroResumo {
        flex-direction: column;
        margin-top: 20px;
    }

    #semTarefasAtrasadas, #tarefasAtrasadas {
        padding-bottom: 10px;
    }

    #demaisTarefas {
        width: 100%;
    }
}