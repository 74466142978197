#processandoSolicitacao {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

#processandoSolicitacao::after,
#processandoSolicitacao::before {
    content: '';  
    box-sizing: border-box;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid #001B48;
    position: absolute;
    animation: animloader 2s linear infinite;
}
  
@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
}

#processandoSolicitacaoContiener p {
    margin: 20px 0;
}