.integracoesContainer {
    display: flex;
    flex-wrap: wrap;
}

.tituloIntegracaoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21px 20px;
    border-bottom: #FF6B6C solid 1px;
}

.cardIntegracoesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 20px;
    box-sizing: border-box;
}

.cardIntegracao {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    width: 300px;
    transition: all 500ms;
}

.cardIntegracao:hover {
    transform: scale(105%);
}

.cardIntegracao p {
    margin: 20px 0 10px 0;
    text-align: justify;
}

.cardIntegracaoIcone {
    width: 150px;
    margin: 50px 0 10px 0;
}

.situacaoIntegracao {
    position: absolute;
    right: -15px;
}

.desativarIntegracaoWhatsApp {
    bottom: 20px;
    right: 20px;
    width: 45px;
}

.qrCodeIntegracaoWhatsApp {
    bottom: 20px;
    right: 80px;
    width: 30px;
    padding: 5px;
    border: #FF6B6C solid 2px;
    border-radius: 5px;
}

.contentOpcoesIntegracoesWhatsApp {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
}

@media (max-width: 1000px) {
    .cardIntegracao {
        width: 100%;
    }
}

