.avatarCadastroContent div {
    display: flex;
    align-items: center;
}

.avatarCadastroContent img:hover{
    position: absolute;
    width: 200px;
    border: 2px solid #FFF;
    border-radius: 50%;
    box-sizing: border-box;
    transition: all 300ms;
}

.cadastroNome {
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: center;
}


@media (max-width: 1000px) {

    #cadastroRazao, #cadastroNome, #cidadeListaCadastro, #estadoCadastro {
        display: none;
    }
}