.contentAdicionarAssinante {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-bottom: 1px solid #FF6B6C;
    padding: 10px 0;
    width: 100%;
    margin: 10px 0;
}

.containerDocumento {
    margin: 10px 0;
}

.containetAssinantes {
    background-color: #FFFFFF;
    border-bottom: 2px solid #FF6B6C;
    border-radius: 10px;
    padding: 0px 8px;
    margin-bottom: 10px;
    padding: 15px 10px;
}

.listaAssinantes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FF6B6C;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0;
}

.listaAssinantes img {
    width: 30px;
    cursor: pointer;
}

.assinantesDocumentos {
    margin: 10px 0;
}

.contentAssinantes {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contentAssinantesAcoes {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
}

.contentAssinantesAcoes img {
    width: 25px;
    cursor: pointer;
}