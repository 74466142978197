.loadingBarraContainer {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    height: 3px;
    width: 100%;
    z-index: 50;
}
.loadingBarra {
    width: 30%;
    height: 3px;
    border: none;
    background-color: #FF6B6C;
    box-sizing: border-box;
    animation: AnimacaoBarra 1s linear infinite;
}

@keyframes AnimacaoBarra {
    0% {
        transform: translateX(-150%);
    }
    100% {
        transform: translateX(150%);
    }
}