#fundoModal, .fundoModal {
    display: flex;
    position: fixed;
    justify-content: center;
    width: 100dvw;
    height: 100dvh;
    overflow-y: auto;
    scrollbar-gutter: stable both-edges;
    scrollbar-width: none;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 200;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.containerModalCentro {
    background-color: #eaf1f2;
    max-width: 900px;
    margin: auto;
    box-sizing: border-box;
    height: max-content;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px 20px;
    margin: 50px 0;
    animation: exibirModal 0.5s;
}

#containerModalNovo, .containerModalNovo {
    position: absolute;
    background-color: #eaf1f2;
    max-width: 1000px;
    min-width: 50%;
    margin-left: 10px;
    box-sizing: border-box;
    min-height: 100vh;
    height: max-content;
    box-sizing: border-box;
    right: 0;
    top: 0;
    border-radius: 10px 0 0 10px;
    padding: 20px;
    animation: exibirModal 0.5s;
}

@keyframes exibirModal {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

#novoContainerTitulo, .novoContainerTitulo {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 15px;
    padding: 0px 0px 10px 0;
    border-bottom: #ff6b6c solid 1px;
}

#adicionarModalIcone, .adicionarModalIcone {
    width: 30px;
}

#formModalNovo section, .formModalNovo section {
    display: flex;
    align-items: flex-end;
    padding: 5px 0;
    gap: 10px;
}

#formModalNovo span, .formModalNovo span {
    display: flex;
    width: 100%;
    flex-direction: column;
}

#formModalNovo #botoesContainer, .formModalNovo .botoesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px 0 0 0;
    margin-top: 10px;
    border-top: #ff6b6c solid 1px;
}

#botoesContainer, .botoesContainer {
    display: flex;
    flex-wrap: wrap;
    bottom: 0;
    right: 20px;
    height: max-content;
    gap: 10px;
    justify-content: flex-end;
}

.areaTexto {
    resize: none;
}

.novoContainerDados {
    margin-top: 10px;
    gap: 15px;
    padding-top: 10px;
    border-top: #ff6b6c solid 1px;
}

.fotoPerfil {
    border-radius: 50%;
    width: 90px;
    height: 90px;
}

.container-formacao {
    padding: 5px;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.295);
    margin-bottom: 5px;
}

.container-formacao > p {
    word-wrap: break-word;
}

.tabelaListaCandidato {
    width: calc(100% - 40px);
    margin: auto;
    padding: 5px;
}

.tabelaListaCandidato > table {
    border-collapse: collapse;
}

.sectionChart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px;
}

.grafico-avaliacao {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.container-avaliacao-disc {
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
}

.chart-body {
    border-radius: 20px;
    width: 280px;
    height: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 30px 0px;
    .chart-serie {
        position: relative;
        max-height: 130px;
        width: 50px;
        border-radius: 5px;
        animation: rise 1s linear forwards;
    }
}

.chart-title {
    text-align: center;
    position: relative;
    top: -25px;
    color: #000;
}

.chart-serie label {
    text-align: center;
    position: absolute;
    bottom: -25px;
    width: 100%;
    font-size: 12px;
    color: #333;
}

.hide-responsive{
    text-transform: capitalize;
}

@keyframes rise {
    0% {
        height: 0%;
    }
    100% {
        height: 100%;
    }
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 150px;
  gap: 1rem;
  .box {
    position: relative;
    width: 4rem;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    word-break: break-word;
    &:nth-of-type(1) .bar {
      background-image: linear-gradient(to bottom, #e20000, #a80101);
    }
    &:nth-of-type(2) .bar {
      background-image: linear-gradient(to bottom, #e7e756, #9b9b39);
    }
    &:nth-of-type(3) .bar {
      background-image: linear-gradient(to bottom, #ac6706, #704304);
    }
    &:nth-of-type(4) .bar {
      background-image: linear-gradient(to bottom, #001ee2, #01169b);
    }
    .bar {
      width: 100%;
      position: absolute;
      bottom: 0;
      max-height: 150px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .skill {
        display: flex;
        align-items: center;
        position: relative;
        margin: auto;
        color: #fff;
        font-weight: bold;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      }
    }
  }
}


@media (max-width: 750px) {
    .hide-responsive {
        display: none;
    }

  .botoesContainer, #botoesContainer{
        display: flex;
        flex-direction: column;
    }
}
