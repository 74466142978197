#dreMes {
    background-color: #F5F5FA;
    grid-column: 3;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    border-left: #FF6B6C solid 5px;
    border-radius: 15px;
    margin: 10px;
    box-sizing: border-box;
}

#dreMes section {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

#dreMes section span {
    display: flex;
    justify-content: space-between;
    width: 90%;
    padding: 20px;
    margin: 0 20px;
    box-sizing: border-box;
    border-bottom: #FF6B6C solid 1px;
    gap: 10px;
}

#dreMesP {
    font-family: 'Panton-BlackCaps';
    font-weight: 900;
    margin-top: 20px;
}

@media (max-width: 1000px) {
    
    #dreMes {
        flex: 1 0 300px;
        height: max-content;
    }
    #dreMes section {
        margin-bottom: 20px;
    }
}