#fundoLogin {
    background-image: linear-gradient(145deg, #F1F1F3 50%, #E6F1FB 100%);
    height: max-content;
    min-height: 100vh;
    width: 100%;
}

#containerEsquerdoLogin {
    position: absolute;
    left: 0;
    width: 40%;
    height: max-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    background: linear-gradient(to right, #F5F6FA 90%, transparent, 10%);
    box-sizing: border-box;
}

#containerDireitoLogin {
    position: absolute;
    background-image: url(../../assets/img/fundo.png);
    mask-image: linear-gradient(to right, transparent 10%, #78869C 65%);
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    right: 0;
    width: 60%;
    height: 100vh;
}

#suporteSistemaGesttor {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 5px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #F1F1F3;
    background-color: #78869C;
    border-radius: 10px;
    transition: all 300ms ease-in-out;
    animation: animacaoSuporte infinite 3s ease-in-out;
}

#suporteSistemaGesttor:hover {
    background-color: #FF6B6C;
}

@keyframes animacaoSuporte {
    0% {
        transform: scale(100%);
    }

    50% {
        transform: scale(105%);
    }

    100% {
        transform: scale(100%);
    }
}

#suporteSistemaGesttor img {
    width: 30px;
}

#logoSistemaGesttor {
    position: absolute;
    top: 10px;
    width: clamp(100px, 150px, 200px);
}

#containerFormLogin {
    width: 75%;
    height: max-content;
}

#containerFormLogin {
    animation: AnumacaoFundoForm 300ms ease-in-out;
}

@keyframes AnumacaoFundoForm {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#containerFormLogin h1 {
    margin-bottom: 5px;
}

#cadastreSe, #recuperarSenha {
    cursor: pointer;
    transition: all 300ms ease-in;
}

#cadastreSe:hover, #recuperarSenha:hover {
    color: #FF6B6C;
}

#formLogin {
    margin-top: 30px;
}

.formCampo {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}

#formLoginCabecalho h1 {
    margin-bottom: 8px;
}

#campoEmail, #campoSenha {
    width: 100%;
    height: 50px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    margin-top: 5px;
    border-radius: 5px;
    padding-left: 10px;
    box-sizing: border-box;
}

#campoEmail:focus, #campoSenha:focus {
    border: 1px solid #78869C;
}

#formEntrar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-sizing: border-box;
    margin: 30px 0 20px 0;
    position: relative;
}

#btnEntrar {
    cursor: pointer;
    border-radius: 5px;
    border: none;
    color: #E2E3E8;
    width: 100%;
    height: 40px;
    transition: all 300ms ease-in;
}

#btnEntrar:hover {
    cursor: pointer;
    background-color: #78869C;
}

@media (max-width: 1000px) {
    #containerDireitoLogin {
        display: none;
    }

    #containerEsquerdoLogin {
        width: 100%;
        border: none;
        border-radius: 10px;
    }

    #containerFormLogin {
        width: 90%;
    }

    #suporteSistemaGesttor p {
        display: none;
    }
}