.acoesIconeDocumento {
  width: clamp(20px, 30px, 50px);
  margin: 10px;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 1px;
}

.acoesIconeDocumento:hover {
  border: solid 1px #ffffff;
  cursor: pointer;
}

@media (max-width: 1000px) {
  #iconeUsuarioSolicitante {
    display: none;
  }

  #primeiraColuna {
    min-width: 40px;
    padding: 10px;
  }
}
