.modalUsuarioOrganizacao {
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
}

.modalUsuarioOrganizacaoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: max-content;
    max-width: 80%;
    padding: 40px 20px 20px 20px;
    margin: 0 20px;
    background-color: #FEFCFF;
    border-radius: 5px;
    animation: animacaoModalFundo 1s;
}

@keyframes animacaoModalFundo {
    0% {
        transform: translateY(-300px);
        opacity: 0;
    }
}

.modalUsuarioOrganizacaoContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
}

.modalUsuarioOrganizacaoContent div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #F1F1F3;
    border-radius: 8px;
    border: #F1F1F3 solid 1px;
    margin: 10px;
    padding: 20px 50px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    width: 300px;
    height: 150px;
    box-sizing: border-box;
    text-align: center;
}

.modalUsuarioOrganizacaoContent div img {
    width: 80px;
    border-radius: 10px;
}

.modalUsuarioOrganizacaoContent div p {
    margin-top: 20px;
}

.modalUsuarioOrganizacaoContent div:hover {
    border: #6A6C72 solid 1px;
}
