.prazoProcesso {
    min-width: 100px;
}

@media (max-width: 1000px) {
    .prazoProcesso, #situacaoProcesso, #progressoProcesso {
        display: none;
    }
    .usuarioResponsavelProcesso {
        display: none;
    }
}