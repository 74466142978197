#paginaNps {
    display: grid;
    align-items: center;
    min-height: 100dvh;
}

#logoGesttorNps {
    position: absolute;
    width: 10em;
    top: 0;
    left: 0;
    padding: 20px;
    cursor: pointer;
}

#conteudoContienerNps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1300px;
    margin: 0 auto;
    animation: animacaoConteudo 0.5s;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
}

@keyframes animacaoConteudo {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

#cabecalhoFormulario {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    align-self: center;
}

#cabecalhoFormulario p {
    margin-top: 20px;
    text-align: center;
}

#atencimentoIcone {
    width: 20em;
}

#formularioContienerNps {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-sizing: border-box;
}

#npsForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: max-content;
    width: 100%;
}

#npsForm p {
    margin: 20px 0px;
}

#npsNota {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

#npsNota span {
    font-family: 'Poppins', sans-serif;
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #97CAD8;
    color: #02457A;
    padding: 5px;
    margin: 5px;
}

#npsNota span:hover {
    transform: scale(120%);
    transition: all 400ms ease-in-out;
    color: #F1F1F3;
}

#npsNota span:hover:nth-child(1) {
    background-color: #FF0000;
}

#npsNota span:hover:nth-child(2) {
    background-color: #D62027;
}

#npsNota span:hover:nth-child(3) {
    background-color: #F05223;
}

#npsNota span:hover:nth-child(4) {
    background-color: #F36F21;
}

#npsNota span:hover:nth-child(5) {
    background-color: #FAA823;
}

#npsNota span:hover:nth-child(6) {
    background-color: #FFCA27;
}

#npsNota span:hover:nth-child(7) {
    background-color: #ECDB12;
}

#npsNota span:hover:nth-child(8) {
    background-color: #E8E73D;
}

#npsNota span:hover:nth-child(9) {
    background-color: #C5D92D;
}

#npsNota span:hover:nth-child(10) {
    background-color: #AFD136;
}

#npsNota span:hover:nth-child(11) {
    background-color: #64B64D;
}

#nota0 {
    background-color: #FF0000 !important;
    transform: scale(120%) !important;
    color: #F1F1F3 !important; 
}

#nota1 {
    background-color: #D62027 !important;
    transform: scale(120%) !important;
    color: #F1F1F3 !important; 
}

#nota2 {
    background-color: #F05223 !important;
    transform: scale(120%) !important;
    color: #F1F1F3 !important; 
}

#nota3 {
    background-color: #F36F21 !important;
    transform: scale(120%) !important;
    color: #F1F1F3 !important; 
}

#nota4 {
    background-color: #FAA823 !important;
    transform: scale(120%) !important;
    color: #F1F1F3 !important; 
}

#nota5 {
    background-color: #FFCA27 !important;
    transform: scale(120%) !important;
    color: #F1F1F3 !important; 
}

#nota6 {
    background-color: #ECDB12 !important;
    transform: scale(120%) !important;
    color: #F1F1F3 !important; 
}

#nota7 {
    background-color: #E8E73D !important;
    transform: scale(110%) !important;
    color: #F1F1F3 !important; 
}

#nota8 {
    background-color: #C5D92D !important;
    transform: scale(120%) !important;
    color: #F1F1F3 !important; 
}

#nota9 {
    background-color: #AFD136 !important;
    transform: scale(120%) !important;
    color: #F1F1F3 !important; 
}

#nota10 {
    background-color: #64B64D !important;
    transform: scale(120%) !important;
    color: #F1F1F3 !important; 
}

#npsComentario {
    width: 100%;
    margin-top: 20px;
}

#npsComentario textarea {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    border-radius: 5px;
    border: none;
    background-color: #F1F1F3;
    padding: 10px 20px;
    box-sizing: border-box;
    height: 200px;
}

#npsComentario textarea:focus {
    outline: none;
    font-weight: bold;
    border: #02457A solid 1px;
}

#npsEnviarDados {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 20px 0;
}

/* RESPONSIVIDADE DA PÁGINA */

@media (max-width: 1000px) {

    #conteudoContienerNps {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    #logoGesttorNps {
        width: 5rem;
    }

    #formularioContienerNps {
        margin-top: 20px;
    }
    
    #npsForm {
        padding: 10px;
    }

    #npsForm p  {
        text-align: center;
    }
    
}
