#centrosContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

#centrosContainer span {
    flex: 1 0 250px;
    display: flex;
    flex-direction: column;
}

#centrosContainer h1 {
    text-align: center;
    margin: 20px 20px 5px 20px;
    padding: 5px 50px;
    background-color: #F1F1F3;
    border-bottom: #FF6B6C solid 1px;
    border-radius: 5px;
}

.centrosContainer {
    display: block;
    max-height: calc(100vh - 155px);
    overflow-y: auto;
}

.centrosContainer::-webkit-scrollbar {
    background-color: #FFFFFF;
    width: 5px;
}

.centrosContainer::-webkit-scrollbar-thumb {
    background-color: #FF6B6C;
    border-radius: 3px;
}

#apagarIconeLead {
    width: 30px;
    margin: 10px;
    box-sizing: border-box;
}

#apagarIconeLead:hover {
    border: solid 1px #FFFFFF;
    border-radius: 50%;
}

@media (max-width: 1000px) {

    #telefoneLead {
        display: none;
    }
}