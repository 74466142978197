#cadastroNome {
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: center;
}


@media (max-width: 1000px) {

    #cadastroRazao, #cadastroNome, #cidadeListaCadastro, #estadoCadastro {
        display: none;
    }
}