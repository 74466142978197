#modalQrCode {
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
}

#modalFundoQrCode {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: max-content;
    height: max-content;
    padding: 40px 20px 20px 20px;
    margin: 0 20px;
    background-color: #FEFCFF;
    border-radius: 5px;
    animation: animacaoModalFundo 1s;
}

@keyframes animacaoModalFundo {
    0% {
        transform: translateY(-300px);
        opacity: 0;
    }
}

#modalFundoQrCode span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}

#modalFundoQrCode span svg {
    width: 250px;
}

#modalFundoDados p {
    text-align: center;
    width: 90%;
    padding: 20px 0;
}

#modalQrCode p:nth-child(1) {
    text-align: center;
    margin-top: 10px;
}

#modalFundoQrCode div {
    margin-bottom: 15px;
}

@media (max-width: 1000px) {
    #modalFundoQrCode p {
        font-size: 0.8rem;
    }
}