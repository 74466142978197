.container-input-menbros-projetos {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}

.container-input-menbros-projetos-adicionados > p {
    cursor: pointer;
    background-color: #ff6b6c;
    margin: 5px 0;
    padding: 5px 10px;
    color: #f9fafb;
    border-radius: 5px;
}


.btn-add-membro {
  font-size: 45px;
  color: #ff6b6c;
  cursor: pointer;
}

.details-container-tags {
  padding: 10px;
  background-color: #f9fafb;
  border-radius: 5px;
  max-width: 100%;
}

