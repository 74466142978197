.opcoesCadastrosContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.opcoesCadastrosLabel {
    background-color: #F5F6FA;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 300ms;
    margin: 5px 0;
    border: 1px solid #F5F6FA;
}

.labelOpcoesCadastroAtiva {
    background-color: rgba(120,143,156, 0.4);
}

.labelOpcoesCadastroAtiva:hover {
    background-color: #F1F1F3;
    border: 1px solid rgba(120,143,156, 0.4);
}

.tabelaCobrancasContainer button {
    margin: 10px 10px 0 10px;
}