#fundoCadastroOrganizacao {
    background-image: linear-gradient(145deg, #F1F1F3 50%, #E6F1FB 100%);
    height: max-content;
    min-height: 100vh;
    width: 100%;
}

#containerEsquerdoCadastro {
    position: absolute;
    left: 0;
    width: 40%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;background: linear-gradient(to right, #F5F6FA 90%, transparent, 10%);
    box-sizing: border-box;
}

#containerDireitoCadastro {
    position: absolute;
    background-image: url(../../assets/img/fundo.png);
    mask-image: linear-gradient(to right, transparent 10%, #78869C 65%);
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    right: 0;
    width: 60%;
    height: 100vh;
}

#containerFormCadastrarOrganizacao {
    width: calc(90% - 20px);
    margin-left: 10%;
    padding: 0 20px 20px 0;
    height: calc(100vh - 120px);
    overflow-y: auto;
}

#containerFormCadastrarOrganizacao::-webkit-scrollbar {
    background-color: #FFFFFF;
    width: 5px;
}

#containerFormCadastrarOrganizacao::-webkit-scrollbar-thumb {
    background-color: #FF6B6C;
    border-radius: 3px;
}

#containerFormCadastrarOrganizacao {
    animation: AnumacaoFundoForm 300ms ease-in-out;
}

@keyframes AnumacaoFundoForm {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#containerFormCadastrarOrganizacao h1 {
    margin-bottom: 5px;
}

#cadastreSe {
    cursor: pointer;
    transition: all 300ms ease-in;
}

#cadastreSe:hover {
    color: #FF6B6C;
}

#formLogin {
    margin-top: 30px;
}

.formCampo {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}

#formLoginCabecalho h1 {
    margin-bottom: 8px;
}

#campoNome, #campoNomeOrganizacao, #campoConfirmarSenha, #campoCnpjCpf {
    width: 100%;
    height: 50px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    margin-top: 5px;
    border-radius: 5px;
    padding-left: 10px;
    box-sizing: border-box;
}

#campoNome:focus, #campoNomeOrganizacao:focus, #campoConfirmarSenha:focus, #campoCnpjCpf:focus {
    border: 1px solid #78869C;
}

#formEntrar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-sizing: border-box;
    margin: 30px 0 20px 0;
    position: relative;
}

#btnCadastrar {
    cursor: pointer;
    background-color: #FF6B6C;
    border-radius: 5px;
    border: none;
    color: #E2E3E8;
    width: 100%;
    height: 40px;
    transition: all 300ms ease-in;
}

#btnCadastrar:hover {
    cursor: pointer;
    background-color: #78869C;
}

@media (max-width: 1000px) {

    #containerDireitoCadastro {
        display: none;
    }

    #containerEsquerdoCadastro {
        width: 100%;
        border: none;
        border-radius: 10px;
    }

    #containerFormCadastrarOrganizacao {
        width: calc(100% - 40px);
        margin-right: 20px;
    }
}

.aceiteTermosContratacao {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    text-align: justify;
}

.aceiteTermosContratacao input[type=checkbox] {
    width: 50px;
}

.aceiteTermosContratacao a {
    text-decoration: none;
    color: #78869C;
    transition: all 300ms ease-in-out;
}

.aceiteTermosContratacao a:hover {
    color: #FF6B6C;
}

.DivDefault form div {
    margin-bottom: 10px;
  }
  
  .DivDefault form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .DivDefault form input {
    width: 100%;
    padding: 5px;
  }