.etapaConfiguracaoInter {
    position: relative;
    margin: 15px 0;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    border-radius: 5px;
    padding: 80px 10px 10px 10px !important;
    border: rgba(154,160,185,.5) solid 1px;
}

.estapaIntegracaoInter {
    position: absolute;
    width: calc(100% - 10px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 10px;
    padding: 10px 10px 10px 5px;
    box-sizing: border-box;
}

.btnContainerDadosInter {
    position: absolute;
    width: 100%;
    bottom: 5px;
    right: 0;
}