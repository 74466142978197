.opcoesTarefasContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.opcoesTarefasLabel {
    background-color: #F5F6FA;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 300ms;
    margin: 5px 0;
    border: 1px solid #F5F6FA;
}

.labelOpcoesTarefaAtiva {
    background-color: rgba(120,143,156, 0.4);
}

.opcoesTarefasLabel:hover {
    background-color: #F1F1F3;
    border: 1px solid rgba(120,143,156, 0.4);
}

.tarefasRecorrentes {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    margin: 20px 0;
}

.automacaoTarefas {
    animation: exibirOpcesAutomacao 300ms ease-in-out;
}

@keyframes exibirOpcesAutomacao {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

.subTarefasContainer {
    position: relative;
}

#adicionarSubtarefasContent {
    position: absolute;
    top: 0;
    right: 20px;
    cursor: pointer;
    transition: all 500ms;
    box-sizing: border-box;
}

.toggleAutomacaoTarefas, .toggleSubTarefas {
    display: none;
}

.toggleAutomacaoTarefas + #toggleAutomacaoTarefas, .toggleSubTarefas + #toggleSubTarefas {
    width: 32px;
    height: 20px;
    background: #6A6C72;
    display: block;
    position: relative;
    border-radius: 20px;
    padding: 2px;
    transition: 200ms;
    cursor: pointer;
}

.toggleAutomacaoTarefas + #toggleAutomacaoTarefas::before, .toggleSubTarefas + #toggleSubTarefas::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #F1F1F3;
    display: block;
    border-radius: 50%;
    right: unset;
    left: 2px;
    top: 2px;
    transition:  200ms;
}

.toggleAutomacaoTarefas:checked + #toggleAutomacaoTarefas, .toggleSubTarefas:checked + #toggleSubTarefas {
    background: #25A388;
}

.toggleAutomacaoTarefas:checked + #toggleAutomacaoTarefas::before, .toggleSubTarefas:checked + #toggleSubTarefas::before {
    content: '';
    left: calc(100% - 22px);
}

.containerModalTarefa textarea {
    height: calc(100dvh - 455px);
}

.tarefaAnexarArquivoContainer, .tarefaComentarioContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    border-radius: 10px;
    padding: 15px 0;
    margin: 10px 0;
}

.tarefaAnexarArquivoContainer input, .tarefaComentarioContainer input {
    display: flex;
    width: 100%;
}

.tarefasAnexos {
    border-bottom: #FF6B6C dotted  2px;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.tarefasAnexosLink  {
    cursor: pointer;
    text-decoration: none;
    margin: 10px;
    transition: all 300ms;
}

.tarefasAnexosLink:hover {
    color: #FF6B6C;
} 

.apagarAnexoContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    transition: all 300ms;
    padding: 5px 10px;
    background-color: #F5F6FA;
    border: #6A6C72 solid 1px;
    border-radius: 10px;
    margin: 0 10px 10px 0;
}

.apagarAnexoContent:hover {
    background-color: #F1F1F3;
}

.apagarAnexoContent img, .comentarioTarefaContent img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.apagarAnexoUsuario {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    width: 250px !important;
    gap: 10px;
    align-items: center;
}

.apagarAnexoUsuario span {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    gap: 10px;
}

.comentarioTarefaContainer, .anexosTarefaContainer {
    height: calc(100dvh - 580px);
    overflow-y: auto;
}

.comentarioTarefaContainer::-webkit-scrollbar, .anexosTarefaContainer::-webkit-scrollbar {
    background-color: #EAF1F2;
    width: 5px;
}

.comentarioTarefaContainer::-webkit-scrollbar-thumb, .anexosTarefaContainer::-webkit-scrollbar-thumb {
    background-color: #FF6B6C;
    border-radius: 3px;
}

.comentarioTarefaContent {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
}

.comentarioTarefaContent p {
    width: 100%;
    padding: 10px;
    border-radius: 0 10px 10px 10px;
    margin: 0 10px 10px 0;
    background-color: #F5F6FA;
    border: #6A6C72 solid 1px;
}