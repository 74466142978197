.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin: 10px;
}

.toggle-switch .toggle-input {
  display: none;
}

.toggle-switch .toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 24px;
  background-color: #d9d9d9;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch .toggle-label::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.toggle-switch .toggle-input:checked + .toggle-label {
  background-color: #ff6b6c;
}

.toggle-switch .toggle-input:checked + .toggle-label::before {
  transform: translateX(16px);
}

.toggle-switch-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listaUsuarioAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-contatos {
  overflow: auto;
  max-height: 200px;
}

.container-contatos > span {
  margin: 5px 0px;
}

.header-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-modal label {
  margin-right: auto;
}

.icone-lado-direito {
  margin-left: auto;
}

.botoes-container-modal {
  display: flex;
  flex-wrap: wrap;
  bottom: 0;
  right: 20px;
  height: max-content;
  justify-content: flex-end;
  gap: 5px;
}

.select-container-modal {
  width: 90%;
  margin: 15px auto;
  display: flex;
}

.container-contatos > .span-destinatarios {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(120, 143, 156, 0.4);
  justify-content: space-between;
  padding: 10px;
  width: auto;
  align-items: center;
}

.span-destinatarios > p {
  word-break: break-all;
  max-width: 100%;
  min-width: auto;
}

.cursor-icone {
  cursor: pointer;
}

.span-destinatarios div {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media (max-width: 700px) {
  .inputs-modal-comunicado {
    flex-direction: column;
    gap: 0px;
  }
}
