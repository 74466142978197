#submenuOpcoes {
    margin: 5px 0px 10px 30px;
}

#submenuOpcoes span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: max-content;
}

#submenuOpcoes span h5 {
    background-color: #00A6A6;
    border-radius: 5px;
    padding: 0px 10px;
    color: #FFF;
    margin-right: 10px;
}

#submenuLink {
    cursor: pointer;
    color: #000000;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    transition: all 300ms ease-in;
}

#submenuLink:hover {
    color: #FF6B6C;
}

@media (max-width: 1600px) {
    #submenuOpcoes {
        margin: 5px 0px 10px 30px;
    }
}