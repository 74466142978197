#containerNenhumResultado {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 120px);
}
#containerNenhumResultado img {
    width: 10%;
    min-width: 100px;
}

#containerNenhumResultado p {
    margin: 40px 0px 0px 0px;
    font-size: 1em;
    font-weight: bolder;
}