#graficoUltimoAtendimento {
    background-color: #F5F5FA;
    flex: 1 0 calc(100vw/2 - 220px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    height: 40vh;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 10px 30px rgba(166,173,201,0.4);
    border-left: #FF6B6C solid 5px;
    border-radius: 15px;
    margin: 10px;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
}

#graficoUltimoAtendimento section {
    width: 100%;
    height: 40vh;
    overflow-y: auto;
}

#ultimoAtendimentoP {
    font-family: 'Panton-BlackCaps';
    font-weight: 900;
    margin-top: 10px;
}

#tabelaUltimosAtendimentos {
    width: 100%;
    margin: 10px 0px;
    border-collapse: collapse;
}

#graficoUltimoAtendimento section::-webkit-scrollbar {
    background-color: #FFFFFF;
    width: 5px;
}

#graficoUltimoAtendimento section::-webkit-scrollbar-thumb {
    background-color: #FF6B6C;
    border-radius: 3px;
}

#atendimentoCabecalho {
    height: 35px;
    border-bottom: #261132 solid 1px;
    text-align: left;
    position: relative;
}

#atendimentoCabecalho th {
    background-color: #F5F5FA;
    font-weight: bold;
    position: sticky;
    top: 0;
}

#clienteNomeAtendimentosConcluidos {
    padding-left: 10px;
}

#listaAtendimentos {
    font-size: 0.8rem;
    height: 30px;
}

@media (max-width: 1000px) {
    #grafico_ultimo_atendimento {
        flex: 1 0 80px;
    }
}